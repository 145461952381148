import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./LeaveOutput.css";
import Message from "./Message";
import { useNavigate } from "react-router-dom";

export default function Leave() {
  function closeContactModal() {
    var modal = document.getElementById("holiday");
    modal.style.display = "none";
    window.history.back();
  }
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const leaveData = {
      start_date: startDate,
      end_date: endDate,
      leave_type_id: 1,
      description: description,
    };
    const apiUrl = "https://ems.crs.prologicsolutions.com.np/api/addleave";
    const bearerToken = localStorage.getItem("token");

    if (!bearerToken) {
      console.error("Token is missing.");
      // Handle the case where the token is missing or invalid
      return;
    }

    // try {
    //   const response = await axios.post(apiUrl, leaveData, {
    //     headers: {
    //       Authorization: `Bearer ${bearerToken}`,
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   if (response.status === 200) {
    //     console.log(response.data.success.message); // Accessing the message from response.data.success
    //   } else {
    //     console.log(response.status);
    //   }
    // } catch (error) {
    //   if (error.response && error.response.status === 401) {
    //     // Checking if error.response exists before accessing status
    //     console.log(error.response.data.error.message); // Accessing the error message from error.response.data.error
    //   } else {
    //     console.log("Unexpected error");
    //   }
    // }

    axios
      .post(apiUrl, leaveData, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Holiday Submitted Successfully")
          setShowModal(true); // Show modal on successful submission
          // Reset form fields after successful submission
          setStartDate("");
          setEndDate("");
          setDescription("");
          navigate("/main");

        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          toast.error(error.response.data.error.message);
          console.log(error.response.data.error.message); // Accessing the error message from error.response.data.error
        } else {
          toast.error("Unexpected error");
        }
      });
  };
  return (
    <>
      <div className="container" id="holiday">
        <div className="row justify-content-center mt-5">
          <div className="col-md-12">
            <span className="closed" onClick={closeContactModal}>
              &times;
            </span>
            <div className="leavefield">
              <h4>Holiday Info</h4>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className="form-group some">
                  <label className="label">Start Date</label> <br />
                  <input
                    type="date"
                    id=""
                    name=""
                    className="inputfield"
                    min={new Date().toISOString().split("T")[0]}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group some">
                  <label className="label">End Date</label> <br />
                  <input
                    type="date"
                    id=""
                    name=""
                    className="inputfield"
                    min={new Date().toISOString().split("T")[0]}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group some">
                  <label className="label">Description</label> <br />
                  <textarea
                    name=""
                    id=""
                    cols="35"
                    rows="4"
                    placeholder="Enter the description of leave."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  ></textarea>
                </div>
                <input type="submit" className="submit_button" />
              </form>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Message
          message="Request for holiday is successfully submitted."
          onCancel={() => setShowModal(false)}
        />
      )}
    </>
  );
}
